<template>
  <div class="flex-wrapper-app">
    <Navbar />
    <div class="merchantdrop-container-main">
      <div class="container" v-if="isOrderMerchantDropoffEligible">
        <div v-if="isSuccess === true">
          <MerchantDropoffSuccess :barcodeId="this.barcodeId" :reason="other" />
        </div>
        <div v-else-if="isSuccess === false">
          <MerchantDropoffFail :barcodeId="this.barcodeId" :reason="errorMessage" />
        </div>
        <div v-else>
          <h2 class="merchantdrop-title">Τοποθέτηση πακέτου σε Locker</h2>
          <br />
          <div
            :class="[
              'merchantdrop-main-container',
              { 'merchantdrop-main-container-loading': isLoading },
            ]"
          >
            <div :class="['loader-overlay', { 'loader-overlay-hide': !isLoading }]">
              <Loader />
            </div>
            <div class="font-size-20">
              <div class="merchantdrop-map-header-container">
                <div>
                  <h3>
                    Αρχική Παραγγελία:
                    <strong class="font-weight-bold">{{ barcodeId }}</strong>
                  </h3>
                  <h3 class="margin-top-5">
                    Κινητό Τηλέφωνο:
                    <strong class="font-weight-bold">{{ phoneNumber }}</strong>
                  </h3>
                </div>
                <div class="flex-end">
                  <h4 class="information-container">
                    <div class="info-icon-margin">
                      <img
                        src="../../../assets/images/info-icon.svg"
                        alt="search-icon"
                        width="24"
                        height="24"
                      />
                    </div>
                    <span class="font-weight-bold"
                      >Η παραγγελία σας είναι συνδεδεμένη στο νούμερο {{ phoneNumber }}. Πρέπει να
                      έχετε πρόσβαση σε αυτή τη συσκευή για να ολοκληρώσετε την τοποθέτηση του.
                    </span>
                  </h4>
                </div>
              </div>

              <div class="merchantdrop-grid-table">
                <h3>
                  Επιλέξτε το Skroutz Point από το χάρτη για να τοποθετήσετε το δέμα σας.
                </h3>

                <button @click="showMap = true && isMobile" class="show-map-button">
                  Δείξε τα Skroutz Points
                </button>

                <div v-show="showMap || !isMobile" class="popup-place-slm-map-container">
                  <div class="popup-place-slm-map" style="text-align:right">
                    <span class="closeMapBtn" @click="closeMap" v-if="isMobile">ⓧ</span>
                    <div id="skroutzLockerMap" class="merchantdrop-map"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="[
                'margin-top-10',
                {
                  invisible: !(
                    merchantDropoffExternalId &&
                    merchantDropoffLockerFriendlyName &&
                    merchantDropofLockerAddress &&
                    !isLoading
                  ),
                },
              ]"
            >
              <p class="merchantdrop-selected-locker-message">
                Έχετε επιλέξει το Skroutz Point:
                <strong class="merchantdrop-selected-highlight">{{
                  merchantDropoffLockerFriendlyName
                }}</strong>
                στην διεύθυνση:
                <strong class="merchantdrop-selected-highlight">{{
                  merchantDropofLockerAddress
                }}</strong>
              </p>
              <div class="slot-size-container">
                <h3 class="font-size-20">
                  Επιλέξτε το μέγεθος του δέματος σας.
                </h3>
                <br />

                <button-selector
                  :availableSlotSizes="this.availableSlotSizes"
                  @buttonSelected="handleButtonSelected"
                  ref="buttonSelector"
                ></button-selector>

                <br />
                <div v-if="selectedButton" class="font-size-16" style="text-align: start">
                  <p class="margin-top-16 font-size-16">
                    Επιλέξατε Μέγεθος:
                    <b class="font-weight-bold">{{ selectedButton.nameGreek }}</b
                    >.
                  </p>

                  Διαστάσεις (εκ.)
                  <b class="font-weight-bold">{{ selectedButton.dimensions }}</b>
                </div>
              </div>
              <div class="bottom-buttons-container">
                <div>
                  <button
                    :class="[
                      'merchantdrop-confirm-button',
                      { 'disable-confirm-button': disableConfirmBtn },
                    ]"
                    @click="confirmOrder"
                    :disabled="disableConfirmBtn"
                  >
                    Επιβεβαίωση
                  </button>
                  <p class="merchantdrop-confirm-button-sub-text">
                    (Θα λάβετε SMS/Viber μήνυμα για το πότε και περαιτέρω οδηγίες)
                  </p>
                </div>
                <div v-if="showCallSupportBtn">
                  <a href="tel:+2111982107" style="">
                    <button class="call-support-button">
                      Κάλεσε το Support
                    </button>
                  </a>
                  <p class="merchantdrop-confirm-button-sub-text">
                    (Αν δεν χωράει το δέμα σε κανένα μέγεθος τότε πρέπει να μιλήσετε με το support
                    για να βρεθεί λύση.)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-else>
        <div :class="['loader-overlay', { 'loader-overlay-hide': !isLoading }]">
          <Loader />
        </div>
        <h4 class="merchantdrop-title">{{ errorMessage ? errorMessage : 'Κάτι πήγε στραβά..' }}</h4>
        <br style="margin: 80px 0 30px 0;" />
        <div class="bottom-buttons-container">
          <div>
            <a href="tel:+2111982107" class="call-support-button-wrapper">
              <button class="call-support-button">
                Κάλεσε το Support
              </button>
            </a>
            <p style="margin-top: 6px; max-width: 400px">
              (Σε περίπτωση που έχει συμβεί κάτι λάθος, επικοινώνησε με την υποστήριξη πελατών)
            </p>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import axios from 'axios';

import Navbar from '../../pages-sections/Navbar.vue';
import Footer from '../../pages-sections/Footer.vue';
import MerchantDropoffSuccess from '../../pages/MerchantDropoff/MerchantDropoffSuccess.vue';
import MerchantDropoffFail from '../../pages/MerchantDropoff/MerchantDropoffFail.vue';
import Loader from '../../loader/Loader.vue';
import ButtonSelector from './ButtonSelector.vue';
export default {
  name: 'MerchantDropoff',
  components: {
    Navbar,
    Footer,
    Loader,
    MerchantDropoffSuccess,
    MerchantDropoffFail,
    ButtonSelector,
  },
  data() {
    return {
      isOrderMerchantDropoffEligible: true,
      barcodeId: '',
      isLoading: false,
      isSuccess: null,
      merchantDropoffExternalId: '',
      merchantDropoffLockerFriendlyName: '',
      merchantDropofLockerAddress: '',
      phoneNumber: '',
      errorMessage: '',
      availableSlotSizes: [],
      selectedButton: null,
      disableConfirmBtn: true,
      showCallSupportBtn: false,
      showMap: false,
      isMobile: false,
    };
  },
  mounted() {
    this.barcodeId = this.$route.params?.barcodeId;
    this.handleHashedBarcode(this.barcodeId);
    this.checkViewportWidth();
    window.addEventListener('resize', this.checkViewportWidth);
  },
  methods: {
    checkViewportWidth() {
      this.isMobile = window.innerWidth < 800;
    },
    async handleHashedBarcode(hashedBarcode) {
      try {
        this.isLoading = true;
        const response = await axios.post(
          `${process.env.VUE_APP_COURIER_API}/user/merchantDropoff/validateHash`,
          {
            voucherHashed: hashedBarcode,
          },
        );
        if (response.status === 200) {
          this.barcodeId = response.data.barcode;
          this.phoneNumber = response.data.phone;
          this.isOrderMerchantDropoffEligible = true;
          this.errorMessage = '';
          this.initMap();
        } else {
          this.errorMessage = 'Κάτι πήγε στραβά...';
          this.isOrderMerchantDropoffEligible = false;
        }
      } catch (error) {
        this.errorMessage = error?.response?.data?.description ?? 'Κάτι πήγε στραβά..';
        this.isOrderMerchantDropoffEligible = false;
      } finally {
        this.isLoading = false;
      }
    },
    async confirmOrder() {
      if (window.confirm('Επιβεβαίωση')) {
        try {
          this.isLoading = true;
          const response = await axios.post(
            `${process.env.VUE_APP_COURIER_API}/user/merchantDropoff/finalize`,
            {
              voucherHashed: this.$route.params.barcodeId,
              lockerSelected: this.merchantDropoffExternalId,
              slotSizeSelected: this.selectedButton.size,
            },
          );
          if (response.status === 200) {
            this.isSuccess = true;
          } else {
            this.isSuccess = false;
          }
        } catch (error) {
          this.errorMessage = error.response.data.description ?? 'unknown';
          this.isSuccess = false;
        } finally {
          this.isLoading = false;
        }
      }
    },
    initMap() {
      var _skroutzPointsMapWidgetOptions = {
        partnerId: 'skou',
        language: 'el',
        type: 'iframe',
        parentElement: '#skroutzLockerMap',
        viewType: 'dropoff',
        afterSelect: selected => {
          if (selected?.id) {
            this.merchantDropoffExternalId = selected.id;
            this.merchantDropoffLockerFriendlyName = selected.name;
            this.merchantDropofLockerAddress = `${selected.street_name} ${selected.street_number}, ${selected.city} ${selected.zip} `;

            if (selected?.model === 'Cleveron') {
              this.availableSlotSizes = [
                {
                  size: 'XS',
                  nameGreek: 'Πολύ Μικρό',
                  dimensions: 'Υψος: 12, Πλάτος: 20, Βάθος: 62',
                },
                { size: 'S', nameGreek: 'Μικρό', dimensions: 'Υψος: 12, Πλάτος: 40, Βάθος: 62' },
                { size: 'M', nameGreek: 'Μεσαίο', dimensions: 'Υψος: 20, Πλάτος: 40, Βάθος: 62' },
                { size: 'L', nameGreek: 'Μεγάλο', dimensions: 'Υψος: 38, Πλάτος: 40, Βάθος: 62' },
                {
                  size: 'XL',
                  nameGreek: 'Πολύ Μεγάλο',
                  dimensions: 'Υψος: 60, Πλάτος: 40, Βάθος: 62',
                },
              ];
            } else {
              this.availableSlotSizes = [
                { size: 'S', nameGreek: 'Μικρό', dimensions: 'Υψος: 11, Πλάτος: 44, Βάθος: 59' },
                { size: 'M', nameGreek: 'Μεσαίο', dimensions: 'Υψος: 24, Πλάτος: 44, Βάθος: 59' },
                { size: 'L', nameGreek: 'Μεγάλο', dimensions: 'Υψος: 50, Πλάτος: 44, Βάθος: 59' },
              ];
            }
            this.availableSlotSizes.push({ size: 'none', nameGreek: 'Κανένα', dimensions: '-' });
          } else if (selected === null) {
            this.merchantDropoffExternalId = '';
            this.merchantDropoffLockerFriendlyName = '';
            this.merchantDropofLockerAddress = '';

            this.availableSlotSizes = [];

            this.resetButtonSelector();
          }
        },
      };
      window._skroutzPointsMapWidgetOptions = _skroutzPointsMapWidgetOptions;

      (function(d) {
        var e = d.createElement('script');
        e.src = `${process.env.VUE_APP_MAP}`;
        e.async = true;
        e.defer = true;
        d.getElementsByTagName('head')[0].appendChild(e);
      })(document);
    },
    handleButtonSelected(button) {
      this.selectedButton = button;

      if (button.size !== 'none') {
        this.disableConfirmBtn = false;
        this.showCallSupportBtn = false;
      } else {
        this.disableConfirmBtn = true;
        this.showCallSupportBtn = true;
      }
    },
    resetButtonSelector() {
      this.$refs.buttonSelector.resetSelection();

      this.disableConfirmBtn = true;
      this.showCallSupportBtn = false;
    },
    closeMap() {
      this.showMap = false;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkViewportWidth);
  },
};
</script>

<style scoped lang="scss">
.merchantdrop-title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 26px;
}

.merchantdrop-description-text {
  display: inline-block;
  font-size: 22px;
  background-color: #8f6eff;
  color: #dbdbdb;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  line-height: 100%;
}

.merchantdrop-header,
.merchantdrop-state {
  width: 100%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.merchantdrop-main-container-loading {
  pointer-events: none;
  background: black;
  opacity: 30%;
}

.merchantdrop-grid-table {
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
  margin-top: 15px;
}

.merchantdrop-map {
  height: 600px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.merchantdrop-title-col {
  font-weight: bold;
}

.merchantdrop-selected-locker-message {
  background-color: #f68b24;
  font-size: 16px;
  padding: 6px;
  line-height: 150%;
  text-align: center;
  color: white;
}

.merchantdrop-selected-highlight {
  font-weight: 700;
  color: white;
}

.bottom-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.merchantdrop-confirm-button {
  min-width: 150px;
  padding: 8px;
  font-size: 26px;
  text-align: center;
  background-color: #279a00;
  &:hover {
    background-color: green;
  }
  height: 55px;
}

.disable-confirm-button {
  cursor: not-allowed;
  background-color: #343c31;
  &:hover {
    background-color: #343c31;
  }

  color: grey;
}

.merchantdrop-confirm-button-main-text {
}
.merchantdrop-confirm-button-sub-text {
  font-size: 12px;
  color: #000;
  width: 150px;
  margin-top: 1px;
  min-height: 100px;
}

.call-support-button {
  min-width: 150px;
  padding: 8px;
  font-size: 20px;
  height: 55px;
  text-align: center;
  background-color: #f68b24;
  &:hover {
    background-color: #f4a154;
  }
}

.call-support-button-wrapper {
  display: inline-block; /* Only take as much width as needed */
  text-decoration: none;
}

.show-map-button {
  display: none;
  margin: 20px 0;
}

@media screen and (max-width: 800px) {
  .merchantdrop-confirm-button {
    font-size: 22px;
    width: 150px;
    height: 60px;
  }
  .call-support-button {
    font-size: 18px;
    height: 60px;
  }

  .show-map-button {
    align-items: center;
    border: 2px solid #111111;
    border-radius: 4px;
    box-sizing: border-box;
    color: #111111;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 16px;
    height: 60px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: 700;
    margin: 20px 8px;

    padding: 10px 20px;
    font-size: 20px;
    background-color: #ffffff;
    width: 200px;
  }

  .show-map-button:after {
    background-color: #e07f00;
    border-radius: 4px;
    content: '';
    display: block;
    height: 58px;
    left: -2px;
    width: 100%;
    position: absolute;
    top: -2px;
    transform: translate(8px, 8px);
    transition: transform 0.2s ease-out;
    z-index: -1;
  }

  .show-map-button:hover {
    background-color: #ffcc79;
  }

  .show-map-button:hover:after {
    transform: translate(0, 0);
  }

  .show-map-button:active {
    background-color: #ffdeda;
    outline: 0;
    font-weight: 400;
  }

  .popup-place-slm-map-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 19997;
  }

  .popup-place-slm-map {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transform: none;
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 20px;
  }
  .closeMapBtn {
    font-size: 50px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 20000;
    color: #f3b645;
  }

  .merchantdrop-map {
    height: 100vh;
  }
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: wait;
}

.loader-overlay-hide {
  display: none;
}

.merchantdrop-container {
  background-repeat: repeat no-repeat !important;
}

.merchantdrop-container-main {
  flex-grow: 1;
  display: flex;
  background: url('../../../assets/images/graphic-bg-tracking.svg');
  background-repeat: no-repeat !important;
  background-position: center bottom;
}

h4 {
  width: 490px;
  font-size: 16px;
  font-weight: normal;
  color: rgb(54, 54, 54);
  line-height: 26px;
}

.information-container {
  display: flex;
  align-items: start;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.merchantdrop-map-header-container {
  display: grid;
  grid-template-columns: auto auto;
}

//smaller mobile screens
@media screen and (max-width: 440px) {
  .bottom-buttons-container {
    .call-support-button {
      font-size: 18px;
      min-width: 140px;
      width: 140px;
    }
    .merchantdrop-confirm-button {
      font-size: 20px;
      min-width: 140px;
      width: 140px;
    }
    .merchantdrop-confirm-button-sub-text {
      font-size: 12px;
      width: 130px;
    }
  }
  .merchantdrop-container-main {
    padding: 56px 0 224px;
  }
}
@media screen and (max-width: 733px) {
  .merchantdrop-container-main {
    padding: 56px 0 224px;
  }
}

@media screen and (min-width: 733px) and (max-width: 961px) {
  .merchantdrop-container-main {
    padding: 136px 0 433px;

    .merchantdrop-container {
      margin: 0 59px;
    }
  }
}
//mobile
@media screen and (max-width: 961px) {
  .merchantdrop-container-main {
    background: url('../../../assets/images/graphic-bg-tracking.svg') bottom center;
    justify-content: bottom;
    background-position: center bottom;
    background-repeat: no-repeat !important;
    width: 100%;

    .merchantdrop-container {
      width: 100%;
    }

    min-height: 50vh;
  }
  .merchantdrop-map-header-container {
    grid-template-columns: auto;
  }

  .information-container {
    margin: 4px 0;
    width: 100%;
  }

  h4 {
    width: 100%;
  }

  .slot-size-container {
  }
}
//desktop
@media screen and (min-width: 961px) {
  .merchantdrop-container {
    max-width: 1320px !important;
  }
  .merchantdrop-container-main {
    padding: 146px 0 100px;
  }
}

//big screen desktop
@media screen and (min-width: 961px) and (max-width: 1500px) {
  .merchantdrop-container {
    margin-left: 64px !important;
  }
}

.font-weight-bold {
  font-weight: 700;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-3-0 {
  margin: 3px 0;
}

.info-container {
  display: flex;
  align-items: start;
  margin: 6px 0;
}

.info-icon-margin {
  margin-right: 3px;
}

.margin-top-5 {
  margin-top: 5px;
}

.flex-end {
  display: flex;
  justify-content: end;
}

.min-height-300 {
  min-height: 300px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-16 {
  font-size: 16px;
}

.text-align-center {
  text-align: center;
}

.invisible {
  visibility: hidden !important;
}

.slot-size-container {
  margin: 50px 0 50px 0;
}
</style>
